export default {
	"title": "Need Support",
	"name": "Name",
	"email": "Email Address",
	"feedback": "Problems & Suggestions",
	"image": "Screenshots or videos(optional)",
	"placeholder": "You can describe your issues or share your ideas with us at here.",
	"desc": "Your feedback will be dealt with as quickly as possible.",
	"submit": "Submit",
	"nofeedback":"The feedback field is a required.",
	"noemail":"The email field is a required.",
	"wrongfeedback":"Your feedback should be at least 5 words.",
	"wrongemail":"Invalid email address",
	"webnet":"Network error.Please try again later.",
	"success":"Thanks for your feedback! Your feedback will be dealt with as quickly as possible.",
	"buttonok":"OK",
	"buttontry":"Try again",
	"imagelimit": "Up to 5 photos and videos.",
	"imagesizelimit": "Unable to upload files larger than 30MB.",
	"loading": "Loading...",
}
